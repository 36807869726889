<template>
  <Page :title="$t('tos.title')" :classes="['page--static', 'page--tos']">
    <div>
      <div v-for="(n, i) in 12" :key="i">
        <h4>{{n}}. {{$t(`tos.${n}.title`)}}</h4>
        <p v-html="$t(`tos.${n}.content`)"></p>
      </div>
    </div>
  </Page>
</template>

<script>
export default {
  name: 'TosPage',
};
</script>
